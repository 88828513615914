module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"The Pain Clinic Australia for Chronic Pain","description":"PCA has achieved a high success rate over 10 years in empowering individuals to seek help for Chronic Pain. Our Licensed doctors create customised treatments based on your individual needs which has lead to a great reputation with 5 star Google reviews.","openGraph":{"type":"website","url":"https://painclinicaustralia.com.au/","title":"The Pain Clinic Australia for Chronic Pain","description":"PCA has achieved a high success rate over 10 years in empowering individuals to seek help for Chronic Pain. Our Licensed doctors create customised treatments based on your individual needs which has lead to a great reputation with 5 star Google reviews."},"twitter":{"cardType":"summary_large_image","url":"https://painclinicaustralia.com.au/"},"metaTags":[{"name":"keywords","content":"australia, au, pain, clinic, pain clinic, pain clinic australia, pca, chronic pain, chronic pain treatment, online assessment, australian doctors"},{"name":"robots","content":"index, follow"},{"name":"language","content":"English"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"theme-color","content":"#000000"}],"languageAlternates":[{"hrefLang":"x-default","href":"https://painclinicaustralia.com.au/"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PDHP6M7","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://painclinicaustralia.com.au/","noTrailingSlash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pain Clinic Australia","short_name":"PCA","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/favicon/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f2971f52c63f3b6c73cb983e934907d3"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
